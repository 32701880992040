<template>
  <div class="comic-detail">
    <div class="intro-section">
      <div class="cover">
        <img :src="comic.cover" alt="漫画封面" class="cover-image" />
      </div>
      <div class="info">
        <div class="title-wrapper">
          <h1 class="title">{{ comic.name }}</h1>
        </div>
        <div class="details">
          <div class="type-wrapper">
            <span
              v-for="theme in comic.comicThemeArray"
              :key="theme.themeCode"
              class="type"
              :style="{ backgroundColor: getThemeColor(theme.themeCode) }"
            >
              {{ theme.themeName }}
            </span>
          </div>
          <div class="status-wrapper">
            <span class="status">{{ comic.serial }}</span>
          </div>
        </div>
        <div class="author-wrapper">
          作者:
          <p class="author">{{ comic.authorName }}</p>
        </div>
        <div class="description-wrapper">
          <p class="description">
            <!-- 显示截断内容或完整内容 -->
            {{ isDescriptionExpanded ? comic.synop : truncatedDescription }}
          </p>
          <!-- 如果需要展开功能，显示按钮 -->
          <button
            v-if="shouldShowToggleButton"
            @click="toggleDescription"
            class="toggle-description"
          >
            {{ isDescriptionExpanded ? '收起' : '查看更多' }}
          </button>
        </div>
      </div>
    </div>

    <!-- 卷目录区域 -->
    <div class="chapters-container">
      <div class="section-header">
        <span class="chapters-dot"></span>
        <h2 class="section-title">卷目录</h2>
      </div>
      <div class="chapters-section">
        <button
          v-for="chapter in loadedChapters"
          :key="chapter"
          class="chapter-button"
          @click="goToReadingPage(chapter)"
        >
          第 {{ chapter }} 卷
        </button>
        <button
          v-if="hasMoreChapters"
          class="chapter-button load-more-button"
          @click="loadNextPage"
        >
          加载更多
        </button>
      </div>
    </div>

    <!-- 相关推荐区域 -->
    <div class="related-section">
      <div class="related-header">
        <span class="related-dot"></span>
        <h3 class="related-title">相关推荐</h3>
      </div>
      <div class="related-grid">
        <div
          v-for="(relatedComic, index) in relatedComics"
          :key="index"
          class="related-card"
          @click="goToComicDetail(relatedComic.id)"
        >
          <img
            :src="relatedComic.cover"
            alt="相关漫画封面"
            class="related-cover"
          />
          <div class="related-info">
            <div class="related-name-wrapper">
              <h4 class="related-name">{{ relatedComic.name }}</h4>
            </div>
            <div class="related-details-wrapper">
              <span
                v-for="theme in relatedComic.comicThemeArray"
                :key="theme.themeCode"
                class="related-type"
                :style="{ backgroundColor: getThemeColor(theme.themeCode) }"
              >
                {{ theme.themeName }}
              </span>
              <span class="related-item">
                <i class="material-icons related-icon">visibility</i>
                {{ relatedComic.clikCount }} 次观看
              </span>
              <span class="related-item">
                <i class="material-icons related-icon">star_border</i>
                {{ relatedComic.favoriteCount }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../scripts/ComicDetail.js"></script>

<style scoped src="../styles/ComicDetail.css"></style>
