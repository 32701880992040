<template>
  <footer class="footer-section">
    <div class="footer-logo">
      <img src="@/assets/logo.png" alt="logo" class="logo-img" />
    </div>
    <p class="footer-text">
      橘子漫画是一家分享经典日本漫画网站。<br />
      在这里你既可以回味童年的回忆，也可以探索新的漫画世界。<br />
    </p>
    <p class="footer-ps">PS：橘子的由来：橘子是小编的第一只柴犬哦。</p>
    <p class="footer-copyright">
      Copyright © 2024 Comic橘子漫画 - All rights reserved
    </p>
  </footer>
</template>

<script src="../scripts/Footer.js"></script>
<style scoped src="../styles/Footer.css"></style>
