import { ElInput, ElIcon, ElCarousel, ElCarouselItem } from 'element-plus'
import 'element-plus/dist/index.css'
import { Search } from '@element-plus/icons-vue'
import axios from 'axios' // 引入 axios 用于请求数据
import API from '@/config/api' // 引入 api 配置

export default {
  components: {
    ElInput,
    ElIcon,
    ElCarousel,
    ElCarouselItem,
    Search
  },
  data() {
    return {
      searchText: '',
      images: [
        require('@/assets/images/ad1.jpg'),
        require('@/assets/images/ad2.jpg'),
        require('@/assets/images/ad3.jpg'),
        require('@/assets/images/ad4.jpg'),
        require('@/assets/images/ad5.jpg')
      ],
      themeColors: {
        10: '#E53935', // 热血 - 红色，象征热情和活力
        20: '#F48FB1', // 恋爱 - 粉色，柔美和浪漫
        30: '#64B5F6', // 校园 - 浅蓝色，青春和希望
        40: '#FFA726', // 冒险 - 橙色，冒险和刺激
        50: '#4DD0E1', // 科幻 - 青色，未来和科技
        60: '#8BC34A', // 生活 - 绿色，自然和生机
        70: '#9C27B0', // 悬疑 - 紫色，神秘和深邃
        71: '#000000', // 恐怖 - 黑色，恐怖和未知
        80: '#7E57C2', // 魔法 - 紫罗兰色，奇幻和魔法
        90: '#FFEB3B' // 竞技 - 黄色，竞争和活力
      },
      categories: [] // 动态加载的漫画分类数据
    }
  },
  mounted() {
    this.fetchCategories() // 页面加载时获取分类数据
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get(API.HOME.INFO) // 替换为实际的接口地址
        if (response.data.code === 0) {
          this.categories = response.data.data.comicVoArray
        } else {
          console.error('Failed to fetch categories:', response.data.message)
        }
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    },
    goToDetail(id) {
      this.$router.push({ name: 'ComicDetail', params: { id } })
    },
    getThemeColor(themeCode) {
      // 如果没有指定颜色，返回默认颜色
      return this.themeColors[themeCode] || '#CCCCCC'
    },
    getHttpImageUrl(url) {
      // 如果 URL 开头是 http 或者 https，直接返回
      if (url.startsWith('http')) {
        return url.replace('https://', 'http://')
      }
      // 否则拼接为 http URL
      return `http://${url}`
    }
  }
}
