import ENV from './env'

const API = {
  HOME: {
    INFO: `${ENV.BASE_URL}/comic/v1/api/home/comic`
  },
  COMIC: {
    DETAIL: `${ENV.BASE_URL}/comic/v1/api/comic/detail`,
    GET_VOL_PAGES: `${ENV.BASE_URL}/comic/v1/api/comic/vol/all`
  }
}

export default API
