import SearchModal from '@/components/SearchModal.vue'
import UserCard from '@/components/UserCard.vue'

export default {
  components: { SearchModal, UserCard },
  data() {
    return {
      showSearchModal: false,
      isUserCardVisible: false,
      hideTimeout: null,
      isMenuOpen: false // 右侧菜单展开状态
    }
  },
  methods: {
    toggleSearch() {
      this.showSearchModal = !this.showSearchModal
    },
    goToCategory(category) {
      this.$router.push({
        name: 'ComicCategory',
        query: { type: category }
      })
    },
    showUserCard() {
      clearTimeout(this.hideTimeout) // 清除隐藏延迟
      this.isUserCardVisible = true // 立即显示卡片
    },
    hideUserCard() {
      // 设置延迟隐藏，确保鼠标从触发区域移动到卡片之间不会触发闪烁
      this.hideTimeout = setTimeout(() => {
        this.isUserCardVisible = false
      }, 500) // 延迟200ms，以防止轻微移动导致的隐藏
    },
    handleMouseEnter() {
      this.showUserCard()
    },
    handleMouseLeave(event) {
      // 确保鼠标不在触发区域和卡片上时才隐藏
      if (
        !this.$refs.userCard ||
        !this.$refs.userCard.$el.contains(event.relatedTarget)
      ) {
        this.hideUserCard()
      }
    },
    goToHome() {
      this.$router.push({ path: '/' })
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    }
  },
  name: 'NavBar'
}
