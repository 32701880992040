import axios from 'axios'
import API from '../config/api'
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {
    NavBar,
    Footer
  },
  data() {
    return {
      comic: {}, // 漫画详情数据
      volTotal: 0, // 总卷数
      relatedComics: [], // 相关推荐
      isDescriptionExpanded: false, // 描述是否展开
      maxDescriptionLines: 4, // 最大显示行数
      chaptersPerPage: 20, // 每页显示的章节数
      loadedChapters: [], // 已加载的章节列表
      themeColors: {
        10: '#E53935', // 热血
        20: '#F48FB1', // 恋爱
        30: '#64B5F6', // 校园
        40: '#FFA726', // 冒险
        50: '#4DD0E1', // 科幻
        60: '#8BC34A', // 生活
        70: '#9C27B0', // 悬疑
        71: '#000000', // 恐怖
        80: '#7E57C2', // 魔法
        90: '#FFEB3B' // 竞技
      }
    }
  },
  props: ['id'], // 接收路由参数 id
  watch: {
    // 监听路由变化
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.localId = to.params.id // 更新本地 id
        this.fetchComicDetail() // 请求新漫画数据
      }
    }
  },
  mounted() {
    // 初始化漫画 ID 和加载数据
    this.localId = this.$route.params.id // 从路由获取初始 id
    this.fetchComicDetail()
  },
  computed: {
    truncatedDescription() {
      const maxChars = this.maxDescriptionLines * 20 // 每行平均 20 个字符
      if (this.comic.synop) {
        return this.comic.synop.length > maxChars
          ? this.comic.synop.slice(0, maxChars) + '...' // 截断
          : this.comic.synop // 不需要截断
      }
      return ''
    },
    shouldShowToggleButton() {
      const maxChars = this.maxDescriptionLines * 20
      return this.comic.synop && this.comic.synop.length > maxChars
    },
    hasMoreChapters() {
      return this.loadedChapters.length < this.volTotal
    }
  },
  methods: {
    async fetchComicDetail() {
      try {
        // 清空之前的数据，避免重复加载
        this.loadedChapters = []
        this.currentPage = 1

        // 确保 id 参数正确传递
        if (!this.localId) {
          console.error('漫画 ID 缺失')
          return
        }

        const response = await axios.post(API.COMIC.DETAIL, {
          id: this.localId
        })
        if (response.data.code === 0) {
          const { comicInfoVo, commendVo } = response.data.data

          // 更新漫画详情
          this.comic = comicInfoVo
          this.volTotal = comicInfoVo.volTotal // 设置总卷数
          this.relatedComics = commendVo

          // 初始化加载第一页章节
          this.loadNextPage()
        } else {
          console.error('获取漫画详情失败', response.data)
        }
      } catch (error) {
        console.error('请求错误:', error)
      }
    },
    toggleDescription() {
      this.isDescriptionExpanded = !this.isDescriptionExpanded
    },
    loadNextPage() {
      if (this.loadedChapters.length >= this.volTotal) return

      const start = this.loadedChapters.length + 1
      const end = Math.min(start + this.chaptersPerPage - 1, this.volTotal)
      const newChapters = Array.from(
        { length: end - start + 1 },
        (_, i) => start + i
      )

      this.loadedChapters = [...this.loadedChapters, ...newChapters]
      this.currentPage++
    },
    goToReadingPage(volNum) {
      this.$router.push({
        name: 'ComicReader',
        params: {
          comicId: this.comic.id, // 当前漫画的 ID
          volNum: volNum // 当前点击的卷号
        }
      })
    },
    goToComicDetail(comicId) {
      this.$router.push({ name: 'ComicDetail', params: { id: comicId } })
    },
    getThemeColor(themeCode) {
      return this.themeColors[themeCode] || '#CCCCCC' // 默认颜色
    }
  }
}
