import axios from 'axios'
import API from '@/config/api'

export default {
  props: ['comicId', 'volNum'], // 从路由参数获取漫画 ID 和卷号
  data() {
    return {
      pages: [] // 当前卷的图片列表
    }
  },
  mounted() {
    this.fetchComicPages() // 初始加载漫画内容
  },
  methods: {
    // 请求当前卷内容
    async fetchComicPages() {
      try {
        const response = await axios.post(API.COMIC.GET_VOL_PAGES, {
          comicId: this.comicId,
          volNum: this.volNum
        })

        if (response.data.code === 0) {
          this.pages = response.data.data.imgUrls || []
          this.resetScrollPosition() // 加载内容后重置页面到顶部
        } else {
          console.error('获取漫画页失败:', response.data)
        }
      } catch (error) {
        console.error('请求漫画内容失败:', error)
      }
    },
    // 切换到上一卷
    prevVolume() {
      if (this.volNum > 1) {
        this.$router.push({
          name: 'ComicReader',
          params: { comicId: this.comicId, volNum: Number(this.volNum) - 1 }
        })
      }
    },
    // 切换到下一卷
    nextVolume() {
      this.$router.push({
        name: 'ComicReader',
        params: { comicId: this.comicId, volNum: Number(this.volNum) + 1 }
      })
    },
    // 重置页面滚动到顶部并切换回第一张图片
    resetScrollPosition() {
      const container = this.$el.querySelector('.comic-reader-container')
      if (container) {
        container.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  },
  watch: {
    // 监听路由参数 volNum 变化
    volNum: {
      immediate: true,
      handler() {
        this.pages = [] // 清空当前图片，避免出现旧内容
        this.fetchComicPages() // 加载新的卷内容
      }
    }
  }
}
