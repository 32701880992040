<template>
  <div class="home">
    <!-- 搜索框区域 -->
    <div class="search-section">
      <p class="search-prompt">Comic Base</p>
      <el-input
        v-model="searchText"
        placeholder="搜索你想看的漫画吧..."
        clearable
        class="search-input"
      >
        <template #suffix>
          <el-icon class="search-icon">
            <Search />
          </el-icon>
        </template>
      </el-input>
    </div>

    <!-- 广告轮播区域 -->
    <div class="carousel-section">
      <el-carousel :interval="2000" arrow="always">
        <el-carousel-item v-for="(image, index) in images" :key="index">
          <img :src="image" class="carousel-image" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 漫画展示区域 -->
    <div class="comic-section" v-if="categories.length">
      <div
        v-for="category in categories"
        :key="category.themeBigCode"
        class="comic-category"
      >
        <h3 class="category-title">{{ category.themeBigName }}</h3>
        <div class="comics-grid">
          <div
            v-for="comic in category.infoVoArray"
            :key="comic.id"
            class="comic-card"
            @click="goToDetail(comic.id)"
          >
            <img
              :src="getHttpImageUrl(comic.cover)"
              alt="漫画封面"
              class="comic-cover"
            />
            <div class="comic-info">
              <div class="comic-name-author">
                <span class="comic-name">{{ comic.name }}</span>
                <span class="comic-author">{{ comic.authorName }}</span>
              </div>
              <div class="comic-themes">
                <span
                  v-for="theme in comic.comicThemeArray"
                  :key="theme.themeCode"
                  class="comic-theme"
                  :style="{ backgroundColor: getThemeColor(theme.themeCode) }"
                >
                  {{ theme.themeName }}
                </span>
              </div>
              <p class="comic-description">{{ comic.synop }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../scripts/Home.js"></script>

<style scoped src="../styles/Home.css"></style>
<style scoped src="../styles/HomeSearch.css"></style>
<style scoped src="../styles/HomeCarousel.css"></style>
<style scoped src="../styles/HomeComics.css"></style>
