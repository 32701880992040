<template>
  <div class="comic-reader-container">
    <!-- 显示漫画图片 -->
    <div
      v-for="(image, index) in pages"
      :key="index"
      class="comic-image-container"
    >
      <img :src="image" alt="Comic Page" class="comic-image" />
    </div>

    <!-- 上一卷、下一卷按钮 -->
    <div class="navigation-buttons">
      <button :disabled="volNum <= 1" @click="prevVolume" class="prev-button">
        上一卷
      </button>
      <button @click="nextVolume" class="next-button">下一卷</button>
    </div>
  </div>
</template>

<script src="../scripts/ComicReader.js"></script>

<style scoped src="../styles/ComicReader.css"></style>
