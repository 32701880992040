const ENV = {
  local: {
    BASE_URL: 'http://localhost:8100' // 本地调试地址
  },
  development: {
    BASE_URL: 'http://localhost:8100' // 开发环境接口地址
  },
  testing: {
    BASE_URL: 'http://testing.api.example.com' // 测试环境接口地址
  },
  production: {
    BASE_URL: 'https://api.comic.leon.org.cn' // 生产环境接口地址
  }
}

// 根据 `process.env.NODE_ENV` 返回对应环境的配置
const currentEnv = process.env.NODE_ENV || 'local'

export default ENV[currentEnv] || ENV.local
