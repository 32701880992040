<template>
  <header class="header">
    <div class="logo" @click="goToHome">
      <img src="@/assets/logo.png" alt="logo" class="logo-image" />
    </div>
    <nav class="nav-links">
      <div class="custom-dropdown">
        <button class="dropbtn">漫画地区</button>
        <div class="dropdown-content">
          <a href="#">日本</a>
        </div>
      </div>
      <div class="custom-dropdown">
        <button class="dropbtn">漫画类型</button>
        <div class="dropdown-content">
          <a @click="goToCategory('热血竞技')">热血竞技</a>
          <a @click="goToCategory('青春恋爱')">青春恋爱</a>
          <a @click="goToCategory('冒险悬疑')">冒险悬疑</a>
          <a @click="goToCategory('幽默格斗')">幽默格斗</a>
        </div>
      </div>
      <a href="#" class="nav-item">漫画剧番</a>
      <a href="#" class="nav-item">使用指南</a>
      <!-- 支付中心按钮 -->
      <div
        class="trigger-area"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        style="position: relative"
      >
        <a href="#" class="nav-item">支付中心</a>
        <UserCard
          v-if="isUserCardVisible"
          ref="userCard"
          :showUserCard="isUserCardVisible"
          @hide="hideUserCard"
        />
      </div>
    </nav>
    <div v-if="isUserCardVisible" class="overlay" @click="hideUserCard"></div>
    <div class="nav-icons">
      <!-- 搜索按钮，点击后显示搜索弹窗 -->
      <button class="icon-button" @click="toggleSearch">
        <i class="material-icons">search</i>
        <!-- 搜索图标 -->
      </button>
      <button class="icon-button">
        <i class="material-icons">notifications</i>
        <!-- 提醒图标 -->
      </button>
      <!-- 登录按钮 -->
      <button @click="$emit('show-login')" class="navbar-login-button">
        <i class="material-icons">account_circle</i>
        登录
      </button>
      <!-- 汉堡菜单按钮 -->
      <button class="hamburger-menu" @click="toggleMenu">
        <i class="material-icons">menu</i>
      </button>
    </div>

    <!-- 搜索弹窗，条件渲染 -->
    <SearchModal v-if="showSearchModal" @close="toggleSearch" />

    <!-- 右侧弹出菜单 -->
    <div :class="['side-menu', { open: isMenuOpen }]">
      <!-- 关闭按钮 -->
      <button class="close-button" @click="toggleMenu">
        <i class="material-icons">close</i>
      </button>
      <div class="menu-content">
        <div class="custom-dropdown">
          <button class="dropbtn">
            漫画地区
            <i class="material-icons drop-icon">expand_more</i>
          </button>
          <div class="dropdown-content">
            <a href="#">日本</a>
          </div>
        </div>
        <div class="custom-dropdown">
          <button class="dropbtn">
            漫画类型
            <i class="material-icons drop-icon">expand_more</i>
          </button>
          <div class="dropdown-content">
            <a @click="goToCategory('热血竞技')">热血竞技</a>
            <a @click="goToCategory('青春恋爱')">青春恋爱</a>
            <a @click="goToCategory('冒险悬疑')">冒险悬疑</a>
            <a @click="goToCategory('幽默格斗')">幽默格斗</a>
          </div>
        </div>
        <a href="#" class="nav-item">漫画剧番</a>
        <a href="#" class="nav-item">使用指南</a>
        <a href="#" class="nav-item">支付指南</a>
      </div>
    </div>
  </header>
</template>

<script src="../scripts/NavBar.js"></script>
<style scoped src="../styles/NavBar.css"></style>
