import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import ComicDetail from '../views/ComicDetail.vue'
import ComicCategory from '@/views/ComicCategory.vue'
import UserCenter from '@/views/UserCenter.vue'
import ComicReader from '@/views/ComicReader.vue' // 导入 ComicRead 组件

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '橘子漫画 - 首页' }
  },
  {
    path: '/comic/:id', // 动态路由参数，id 表示漫画的唯一标识
    name: 'ComicDetail',
    component: ComicDetail,
    props: true, // 将路由参数作为属性传递给 ComicDetail
    meta: { title: '橘子漫画 - 漫画详情' }
  },
  {
    path: '/category',
    name: 'ComicCategory',
    component: ComicCategory,
    meta: { title: '橘子漫画 - 分类' }
  },
  {
    path: '/user-center/:section?',
    name: 'UserCenter',
    component: UserCenter,
    props: true,
    meta: { title: '橘子漫画 - 个人中心' }
  },
  {
    path: '/comic/reader/:comicId/:volNum', // 将 volNum 作为路径参数
    name: 'ComicReader',
    component: ComicReader,
    props: true, // 启用路由参数传递
    meta: { title: '橘子漫画 - 我看看！' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 在路由切换前修改页面标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '橘子漫画' // 默认标题
  next()
})

export default router
